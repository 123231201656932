import React from "react";
import Navbar from "../components/main_navbar/MainNavbar";
import Footer from "../components/footer/Footer";
import {About} from "../components/about/About";

export class AboutPage extends React.Component {
  render() {
    return (
      <div className="h-100 d-flex flex-column">
        <Navbar navbarVisibleScroll={0} />

        <About />

        <Footer />
      </div>
    );
  }
}
