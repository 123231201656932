import React from "react";
import {Col, Row} from "reactstrap";
import {Translation} from "react-i18next";
// import Fade from "react-reveal";
import Slide from "react-reveal/Slide";

export class IntroSection extends React.Component {
  render() {
    return this.props.imagePosition === "right" ? (
      <div>
        <Row className={"presentationRow"}>
          <Col md={6} sm={12}>
            <Slide bottom>
              <Translation>
                {(t) => (
                  <h1
                    className={"text-md-left text-sm-center presentationHeader"}
                  >
                    {" "}
                    {t(this.props.header)}{" "}
                  </h1>
                )}
              </Translation>

              <div className="text-left text-secondary">
                <ul className="introListPadding">
                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext1)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext2)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext3)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>
                </ul>
              </div>
            </Slide>
          </Col>

          <Col md={6} sm={12}>
            {this.props.visualContentType === "video" ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              ></video>
            ) : (
              <img
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              />
            )}
          </Col>
        </Row>
      </div>
    ) : (
      <div>
        <Row className="presentationRow d-flex d-sm-none">
          <Col sm={12}>
            <Slide bottom>
              <Translation>
                {(t) => (
                  <h1
                    className={
                      "text-md-left  text-sm-center  presentationHeader"
                    }
                  >
                    {" "}
                    {t(this.props.header)}{" "}
                  </h1>
                )}
              </Translation>

              <div className="text-left text-secondary">
                <ul className="introListPadding">
                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext1)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext2)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3 sectionSubtext"}>
                          {" "}
                          {t(this.props.subtext3)}{" "}
                        </h3>
                      )}
                    </Translation>
                  </li>
                </ul>
              </div>
            </Slide>
          </Col>

          <Col sm={12}>
            {this.props.visualContentType === "video" ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              ></video>
            ) : (
              <img
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              />
            )}
          </Col>
        </Row>

        <Row className="presentationRow d-none d-sm-flex">
          <Col md={6}>
            {this.props.visualContentType === "video" ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              ></video>
            ) : (
              <img
                src={this.props.visualContent}
                className={"introPageVisualContent"}
              />
            )}
          </Col>

          <Col md={6} sm={12}>
            <Slide bottom>
              <Translation>
                {(t) => (
                  <h1
                    className={"text-md-left text-sm-center presentationHeader"}
                  >
                    {" "}
                    {t(this.props.header)}{" "}
                  </h1>
                )}
              </Translation>

              <div className="text-left text-secondary">
                <ul className="introListPadding">
                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3"}> {t(this.props.subtext1)} </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3"}> {t(this.props.subtext2)} </h3>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <Translation>
                      {(t) => (
                        <h3 className={"m-3"}> {t(this.props.subtext3)} </h3>
                      )}
                    </Translation>
                  </li>
                </ul>
              </div>
            </Slide>
          </Col>
        </Row>
      </div>
    );
  }
}
