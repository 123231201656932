import React from "react";
import HeaderBar from "../offer/HeaderBar";

export class DestinationView extends React.Component {
  render() {
    const { countryCode, city, country, image } = this.props;
    return (
      <div
        style={{
          padding: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HeaderBar countryCode={countryCode} city={city} country={country} />
        <img
          style={{ borderRadius: "20px" }}
          width="100%"
          src={image}
          alt={city}
        />
      </div>
    );
  }
}
