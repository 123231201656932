import React from "react";
import {CardText, Col, Row} from "reactstrap";
import {categoriesToString} from "../../services/kiwiService";

export class CategoriesBar extends React.Component {
  render() {
    return (
      <Row className="categoriesTestRow">
        <Col className="col-12">
          <CardText className="text-left text-secondary">
            {categoriesToString(this.props.categories)}
          </CardText>
        </Col>
      </Row>
    );
  }
}

export default CategoriesBar;
