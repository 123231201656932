import React from "react";
import {Container} from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {DestinationView} from "./DestinationView";
import {destinations} from "./destinations_data";
import {Translation} from "react-i18next";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export class DestinationShowcase extends React.Component {
  renderDestination = (destination) => (
    <DestinationView
      countryCode={destination.countryCode}
      city={destination.city}
      country={destination.country}
      image={destination.image}
    />
  );

  render() {
    return (
      <Container fluid>
        <Translation>
          {(t) => <h1>{t("THOUSANDS_OF_DESTINATIONS")} </h1>}
        </Translation>
        <br />
        <br />
        <br />
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
        >
          {destinations.map((destination) =>
            this.renderDestination(destination),
          )}
        </Carousel>
      </Container>
    );
  }
}
