import React from "react";
import Container from "reactstrap/es/Container";
import {Col, Row} from "reactstrap";
import {Translation} from "react-i18next";

export class About extends React.Component {
  render() {
    return (
      <Container className="aboutContainer">
        <Row>
          <Col>
            <Translation>{(t) => <h1>{t("ABOUT_US")}</h1>}</Translation>
          </Col>
        </Row>
        <Row>
          <Col>
            <Translation>{(t) => <h3>{t("OUR_MISSION")}</h3>}</Translation>
          </Col>
        </Row>

        <br />
        <br />

        <Row>
          <Col>
            <Translation>{(t) => <h2>{t("CONTACT_US")}</h2>}</Translation>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>E-mail:</strong> support@columbus.sk
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
