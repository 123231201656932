import React, {useState} from "react";
import {Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,} from "reactstrap";
import classnames from "classnames";
import logoWhite from "../../assets/img/logos/logo-row-white.png";
import logoColorized from "../../assets/img/logos/logo-row-colorized.png";
import i18next from "i18next";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import {Translation} from "react-i18next";

const MainNavbar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
    updateNavbarColor(collapsed);
  };

  const handleChange = (x) => {
    i18next.changeLanguage(x, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      //t('key'); // -> same as i18next.t
    });
  };

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [isNavbarTransparent, setNavbarIsTransparent] = React.useState(false);

  const updateNavbarColor = (collapsed) => {
    const navbarEffect = props.navbarVisibleScroll;
    if (
      document.documentElement.scrollTop > navbarEffect - 1 ||
      document.body.scrollTop > navbarEffect - 1 ||
      !collapsed
    ) {
      setNavbarColor("");
      setNavbarIsTransparent(false);
    } else if (
      document.documentElement.scrollTop < navbarEffect ||
      document.body.scrollTop < navbarEffect
    ) {
      setNavbarColor("navbar-transparent");
      setNavbarIsTransparent(true);
    }
  };

  React.useEffect(() => {
    updateNavbarColor(collapsed);

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color="light"
      light
      expand="lg"
    >
      <Container fluid>
        <NavbarBrand href="/" className="menuSides text-center">
          <img
            src={
              navbarColor === "navbar-transparent" ? logoWhite : logoColorized
            }
            alt="Columbus"
            className="columbusLogo"
          />
        </NavbarBrand>

        <NavbarToggler className="ml-auto" onClick={toggleNavbar} />

        <Collapse isOpen={!collapsed} navbar className="menuSides">
          <Nav navbar className="ml-auto">
            <NavItem className="menuElement mobile-margin-top">
              <NavLink
                href="https://www.facebook.com/columbusworld"
                target="_blank"
              >
                <i aria-hidden={true} className="fa fa-facebook-official" />
                Facebook
              </NavLink>
            </NavItem>

            <NavItem className="menuElement">
              <NavLink
                href="https://www.instagram.com/columbusworld"
                target="_blank"
              >
                <i aria-hidden={true} className="fa fa-instagram" />
                Instagram
              </NavLink>
            </NavItem>

            <NavItem className="menuElement mobile-margin-top">
              <Translation>
                {(t) => (
                  <Button
                    className={"buttonStyle"}
                    href="#download-app"
                    style={{
                      borderRadius: "15px",
                      color: isNavbarTransparent ? "white" : "black",
                    }}
                    size="lg"
                    outline
                  >
                    {t("DOWNLOAD_APP_BUTTON")}
                  </Button>
                )}
              </Translation>
            </NavItem>

            <NavItem className="menuElement">
              <LanguageSelection
                color={isNavbarTransparent ? "white" : "black"}
              />
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
