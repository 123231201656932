import React from "react";
import {CardText, Col, Row} from "reactstrap";
import RouteItem from "./RouteItem";

export class RoutesBar extends React.Component {
  getIndexOfDestinationTakeoff() {
    for (let i = 0; i < this.props.data.route.length; i++) {
      if (this.props.data.route[i].mapIdto === this.props.data.mapIdto)
        return i + 1;
    }
  }

  renderRoutes() {
    let children = [];
    let indexOfDestinationTakeoff = this.getIndexOfDestinationTakeoff();
    for (let i = 0; i < this.props.data.route.length / 2; i++) {
      children.push(
        <Row>
          <Col className="routeItemCol col-sm-12 col-md-6">
            <RouteItem item={this.props.data.route[i]} />
          </Col>
          {/*<div className="vl"></div>*/}
          <Col className="routeItemCol col-sm-12 col-md-6">
            <RouteItem
              item={this.props.data.route[indexOfDestinationTakeoff + i]}
            />
          </Col>
        </Row>,
      );
    }

    return <div>{children}</div>;
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <CardText className="text-left font-weight-bolder">
              Outbound
            </CardText>
          </Col>
          {/*<div className="vl"></div>*/}
          <Col>
            <CardText className="text-left ml-2 font-weight-bolder">
              Return
            </CardText>
          </Col>
        </Row>
        {this.renderRoutes()}
      </div>
    );
  }
}

export default RoutesBar;
