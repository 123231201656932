import React from "react";
import {Button, CardImg, CardText, Col, Modal, Row} from "reactstrap";
import {locationIdToImgUrl} from "../../services/kiwiService";
import CategoriesBar from "./CategoriesBar";
import HeaderBar from "./HeaderBar";
import OfferInfoBar from "./OfferInfoBar";
import RoutesBar from "./RoutesBar";
import Loader from "react-loader-spinner";

// core components

class OfferModal extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Modal
        className="mt-3"
        isOpen={this.props.isOpen}
        toggle={this.props.onChange}
        size="lg"
      >
        <div className="offerBody">
          <Row className="colPadding">
            <Col className="col-3 routeItem centerContent">
              <Loader type="ThreeDots" color="black" height={40} width={40} />
            </Col>

            <Col className="col-9">
              <HeaderBar
                countryCode={data.countryTo.code}
                city={data.cityTo}
                country={data.countryTo.name}
              />

              <div style={{ maxHeight: "450px", overflow: "hidden" }}>
                <CardImg
                  variant="top"
                  src={locationIdToImgUrl(
                    this.props.data ? this.props.data.mapIdto : null,
                  )}
                />
              </div>

              <CategoriesBar categories={this.props.data.categories} />

              <div className="mb-3">
                <RoutesBar data={this.props.data} />
              </div>

              <hr />

              <div className="mb-3">
                <OfferInfoBar
                  showFromLocation={false}
                  data={this.props.data}
                  showTimeRange={true}
                />
              </div>

              <hr />

              <Row className="text-right">
                <Col className="col-4">
                  <Row className="colPadding">
                    <CardText className="text-left">Provided by&nbsp;</CardText>
                    <CardText className="text-left text-primary">
                      <a target="_blank" href="https://www.kiwi.com/">
                        Kiwi.com
                      </a>
                    </CardText>
                  </Row>
                </Col>
                <Col className="col-8">
                  <Button
                    color="secondary"
                    onClick={this.props.onChange}
                    className="m-3"
                  >
                    Close
                  </Button>

                  <a
                    href={this.props.data.deep_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-right"
                  >
                    <Button color="primary">Book</Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default OfferModal;
