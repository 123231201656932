import React from "react";
import video from "../../assets/video/bus_video.mp4";
import {Col, Container, Row} from "reactstrap";
import androidLogo from "../../assets/img/logos/android_black.svg";
import appleLogo from "../../assets/img/logos/apple_black.svg";
import Collapse, {Panel} from "rc-collapse";
import {Translation} from "react-i18next";
import {IntroSection} from "./IntroSection";
import introContent1 from "../../assets/img/screens/columbus_data_feed.mp4";
import introContent2 from "../../assets/img/screens/columbus_filters.mp4";
import introContent3 from "../../assets/img/screens/columbus_map.mp4";
import introContent4 from "../../assets/img/screens/columbus_booking.mp4";
import {DestinationShowcase} from "../destinations_showcase/DestinationShowcase";

require("rc-collapse/assets/index.css");

export class IntroContainer extends React.Component {
  scrollHandler() {
    let element = document.getElementById("opacityElement");

    let distanceToTop =
      window.pageYOffset + element.getBoundingClientRect().top;
    let elementHeight = element.offsetHeight;
    let scrollTop = document.documentElement.scrollTop;

    let opacity = 1;

    if (scrollTop > 0) {
      opacity =
        (distanceToTop + elementHeight - scrollTop) /
        (distanceToTop + elementHeight);
    }

    if (opacity >= 0) {
      element.style.opacity = opacity;
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  render() {
    return (
      <div className="flex-1">
        <div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="backgroundVideo"
            src={video}
            type="video/mp4"
          ></video>
        </div>

        <Container className={"headerTitleBox"}>
          <Row>
            <Col>
              <Translation>
                {(t) => (
                  <div>
                    <h1 id="opacityElement" className={"text-center titleText"}>
                      {t("INTRO_TITLE_DISCOVER_THE_WORLD")}{" "}
                    </h1>
                  </div>
                )}
              </Translation>
            </Col>
          </Row>
        </Container>

        <Container className={"mainTitleBox"}>
          <a id={"download-app"} className="anchor" />
          <Row>
            <Col className={"presentationHeader"}>
              <Translation>
                {(t) => (
                  <h1 className={"text-center mt-3 mb-5"}>
                    {" "}
                    {t("INTRO_TITLE_DOWNLOAD_THE_APP")}{" "}
                  </h1>
                )}
              </Translation>
            </Col>
          </Row>

          <Row>
            <Col xs={0} md={4} className="text-center" />

            <Col xs={6} md={2} className="text-center">
              <a
                href="https://apps.apple.com/us/app/columbus-cheap-flights/id1555124342"
                target="_blank"
              >
                <img
                  src={appleLogo}
                  alt="Get from App Store"
                  style={{ height: "40px" }}
                />
              </a>
            </Col>

            <Col xs={6} md={2} className="text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.therabit.columbus"
                target="_blank"
              >
                <img
                  src={androidLogo}
                  alt="Get from Google Play"
                  style={{ height: "40px" }}
                />
              </a>
            </Col>

            <Col xs={0} md={4} className="text-center" />
          </Row>
        </Container>

        <hr />

        <Container>
          <IntroSection
            imagePosition="right"
            visualContentType="video"
            visualContent={introContent1}
            header="INTRO_SECTION_1_HEADER"
            subtext1="INTRO_SECTION_1_SUBTEXT_1"
            subtext2="INTRO_SECTION_1_SUBTEXT_2"
            subtext3="INTRO_SECTION_1_SUBTEXT_3"
          />

          <hr />

          <IntroSection
            imagePosition="left"
            visualContentType="video"
            visualContent={introContent2}
            header="INTRO_SECTION_2_HEADER"
            subtext1="INTRO_SECTION_2_SUBTEXT_1"
            subtext2="INTRO_SECTION_2_SUBTEXT_2"
            subtext3="INTRO_SECTION_2_SUBTEXT_3"
          />

          <hr />

          <IntroSection
            imagePosition="right"
            visualContentType="video"
            visualContent={introContent3}
            header="INTRO_SECTION_3_HEADER"
            subtext1="INTRO_SECTION_3_SUBTEXT_1"
            subtext2="INTRO_SECTION_3_SUBTEXT_2"
            subtext3="INTRO_SECTION_3_SUBTEXT_3"
          />

          <hr />

          <IntroSection
            imagePosition="left"
            visualContentType="video"
            visualContent={introContent4}
            header="INTRO_SECTION_4_HEADER"
            subtext1="INTRO_SECTION_4_SUBTEXT_1"
            subtext2="INTRO_SECTION_4_SUBTEXT_2"
            subtext3="INTRO_SECTION_4_SUBTEXT_3"
          />
        </Container>

        <Container fluid>
          <DestinationShowcase />
        </Container>

        <Container>
          <Row className={"questionsRow"}>
            <Col md={6} sm={12}>
              <Translation>
                {(t) => (
                  <h1 className={"text-center m-5"}>
                    {" "}
                    {t("INTRO_QUESTIONS_HEADER")}{" "}
                  </h1>
                )}
              </Translation>
            </Col>
            <Col md={6} sm={12}>
              <Translation>
                {(t) => (
                  <Collapse accordion={false}>
                    <Panel
                      header={t("INTRO_QUESTION_1_TEXT")}
                      headerClass="questionsTab"
                    >
                      {t("INTRO_QUESTION_1_ANSWER")}
                    </Panel>
                    <Panel
                      header={t("INTRO_QUESTION_2_TEXT")}
                      headerClass="questionsTab"
                    >
                      {t("INTRO_QUESTION_2_ANSWER")}
                    </Panel>
                    <Panel
                      header={t("INTRO_QUESTION_3_TEXT")}
                      headerClass="questionsTab"
                    >
                      {t("INTRO_QUESTION_3_ANSWER")}
                    </Panel>
                    <Panel
                      header={t("INTRO_QUESTION_4_TEXT")}
                      headerClass="questionsTab"
                    >
                      {t("INTRO_QUESTION_4_ANSWER")}
                    </Panel>
                    <Panel
                      header={t("INTRO_QUESTION_5_TEXT")}
                      headerClass="questionsTab"
                    >
                      {t("INTRO_QUESTION_5_ANSWER")}
                    </Panel>
                  </Collapse>
                )}
              </Translation>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
