import React from "react";
import {CardText, Col, Row} from "reactstrap";
import Icon from "@mdi/react";
import {mdiAirplaneLanding, mdiAirplaneTakeoff} from "@mdi/js";

export class RouteItem extends React.Component {
  getTime(timeStamp) {
    var date = new Date(timeStamp * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();

    return hours + ":" + minutes.substr(-2);
  }

  render() {
    return (
      <div className="mt-2 routeItem">
        <Row>
          <Col xs={3} className="centerContent">
            <img
              src={
                "https://images.kiwi.com/airlines/64x64/" +
                this.props.item.airline +
                ".png"
              }
              alt={this.props.item.airline}
              className="airlineLogo"
            />
          </Col>

          <Col xs={9}>
            <Row className="colPadding">
              <Icon
                className="text-left ml-2"
                path={mdiAirplaneTakeoff}
                size={1.0}
              />
              <CardText className="text-left text-secondary ml-2">
                {this.getTime(this.props.item.dTime)}
              </CardText>

              <CardText className="text-left text-secondary ml-2">
                {this.props.item.cityFrom}
              </CardText>
            </Row>
            <Row className="colPadding">
              <Icon
                className="text-left ml-2"
                path={mdiAirplaneLanding}
                size={1.0}
              />

              <CardText className="text-left text-secondary ml-2">
                {this.getTime(this.props.item.aTime)}
              </CardText>

              <CardText className="text-left text-secondary ml-2">
                {this.props.item.cityTo}
              </CardText>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RouteItem;
