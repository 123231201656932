import React from "react";
import Navbar from "../components/main_navbar/MainNavbar";
import Footer from "../components/footer/Footer";
import {TermsOfUse} from "../components/terms_of_use/TermsOfUse";

export class TermsOfUsePage extends React.Component {
  render() {
    return (
      <div className="h-100 d-flex flex-column">
        <Navbar navbarVisibleScroll={0} />

        <TermsOfUse />

        <Footer />
      </div>
    );
  }
}
