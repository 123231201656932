import React from "react";
import Navbar from "../components/main_navbar/MainNavbar";
import Footer from "../components/footer/Footer";
import {WallContainer} from "../components/wall/WallContainer";

export class FlightsPage extends React.Component {
  render() {
    const from = new URLSearchParams(this.props.location.search).get("from");

    return (
      <div className="h-100 d-flex flex-column">
        <Navbar navbarVisibleScroll={0} defaultFromLocationId={from} />

        <WallContainer key={from} from={from} />

        <Footer />
      </div>
    );
  }
}
