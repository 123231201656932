import React from "react";
import "../styles/main.scss";
import {RouterContainer} from "../pages/RouterContainer";

function App() {
  return (
    <div className="App h-100">
      <RouterContainer />
    </div>
  );
}

export default App;
