import React from "react";
import {CardText, Row} from "reactstrap";
import {isoCountriesToPng} from "../../assets/img/imageImporter";

class HeaderBar extends React.Component {
  render() {
    const { countryCode, city, country } = this.props;
    return (
      <div>
        <Row>
          <div className="colPadding">
            <img
              src={isoCountriesToPng[countryCode?.toUpperCase()]}
              alt={countryCode}
              className="countryFlagImg"
            />
          </div>

          <div className="colPadding">
            <Row>
              <CardText className="offer-header-city-name"> {city} </CardText>
            </Row>
            <Row>
              <CardText className="offer-header-country-name">
                {" "}
                {country}{" "}
              </CardText>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}

export default HeaderBar;
