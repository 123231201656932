import React from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {HomePage} from "./HomePage";
import {FlightsPage} from "./FlightsPage";
import {TermsOfUsePage} from "./TermsOfUsePage";
import {AboutPage} from "./AboutPage";
import ScrollToTop from "./ScrollToTop";
import {PrivacyPolicyPage} from "./PrivacyPolicyPage";

export class RouterContainer extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Route exact path="/" component={HomePage} />

          <Route exact path="/flights" component={FlightsPage} />

          <Route exact path="/terms-of-use" component={TermsOfUsePage} />

          <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

          <Route exact path="/about" component={AboutPage} />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}
