import React from "react";
import Navbar from "../components/main_navbar/MainNavbar";
import Footer from "../components/footer/Footer";
import {PrivacyPolicy} from "../components/privacy_policy/PrivacyPolicy";

export class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <div className="h-100 d-flex flex-column">
        <Navbar navbarVisibleScroll={0} />

        <PrivacyPolicy />

        <Footer />
      </div>
    );
  }
}
