export const destinations = [
  {
    city: "Barcelona",
    country: "Spain",
    countryCode: "es",
    image: "https://images.columbus.world/small/barcelona_es.jpeg",
  },
  {
    city: "Budapest",
    country: "Hungary",
    countryCode: "hu",
    image: "https://images.columbus.world/small/budapest_hu.jpeg",
  },
  {
    city: "London",
    country: "England",
    countryCode: "gb",
    image: "https://images.columbus.world/small/london_gb.jpeg",
  },
  {
    city: "Rome",
    country: "Italy",
    countryCode: "it",
    image: "https://images.columbus.world/small/rome_it.jpeg",
  },
  {
    city: "Berlin",
    country: "Germany",
    countryCode: "de",
    image: "https://images.columbus.world/small/berlin_de.jpeg",
  },
  {
    city: "Bratislava",
    country: "Slovakia",
    countryCode: "sk",
    image: "https://images.columbus.world/small/bratislava_sk.jpeg",
  },
  {
    city: "Vienna",
    country: "Austria",
    countryCode: "at",
    image: "https://images.columbus.world/small/vienna_at.jpeg",
  },
  {
    city: "Cancún",
    country: "Mexico",
    countryCode: "mx",
    image: "https://images.columbus.world/small/cancun_mx.jpeg",
  },
  {
    city: "Menorca",
    country: "Spain",
    countryCode: "es",
    image: "https://images.columbus.world/small/minorca_es.jpeg",
  },
  {
    city: "Santorini",
    country: "Greece",
    countryCode: "gr",
    image: "https://images.columbus.world/small/santorini_gr.jpeg",
  },
  {
    city: "Rhodes",
    country: "Greece",
    countryCode: "gr",
    image: "https://images.columbus.world/small/rhodes_gr.jpeg",
  },
  {
    city: "Phuket",
    country: "Thailand",
    countryCode: "th",
    image: "https://images.columbus.world/small/phuket-city_th.jpeg",
  },
];
