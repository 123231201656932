import React from "react";
import OfferModal from "./OfferModal";
import {CardImg} from "reactstrap";
import {locationIdToSmallImgUrl} from "../../services/kiwiService";
import CategoriesBar from "./CategoriesBar";
import HeaderBar from "./HeaderBar";
import OfferInfoBar from "./OfferInfoBar";

export class Offer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <a
          href="javascript:void(0);"
          onClick={this.toggleModal}
          className="text-decoration-none text-dark"
        >
          {/*href='#'*/}
          <div className="w-100 mt-5 card">
            <div className="offerHeader">
              <HeaderBar
                countryCode={data.countryTo.code}
                city={data.cityTo}
                country={data.countryTo.name}
              />
            </div>

            <CardImg
              variant="top"
              src={locationIdToSmallImgUrl(
                this.props.data ? this.props.data.mapIdto : null,
              )}
            />

            <CategoriesBar categories={this.props.data.categories} />

            {/*<hr/>*/}

            <div className="offerBody">
              <OfferInfoBar
                showFromLocation={true}
                data={this.props.data}
                showTimeRange={true}
              />
            </div>
          </div>
        </a>

        <OfferModal
          isOpen={this.state.modal}
          onChange={this.toggleModal}
          data={this.props.data}
        />
      </div>
    );
  }
}
