import React from "react";
import {loadDestinations} from "../../services/kiwiService";
import Loader from "react-loader-spinner";
import {Col, Container, Row} from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Offer} from "../offer/Offer";

export class WallContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { destinations: [] },
      fetchState: "loading",
    };
  }

  componentDidMount() {
    loadDestinations(this.props.from)
      .then((data) => this.setState({ data: data, fetchState: "done" }))
      .catch(() => {
        this.setState({
          fetchState: "fail",
        });
      });
  }

  createTable = () => {
    let table = [];

    // Outer loop to create parent
    let children = [];
    for (let i = 0; i < this.state.data.destinations.length / 3; i++) {
      //Inner loop to create children
      for (let j = 0; j < 3; j++) {
        i * 3 + j < this.state.data.destinations.length
          ? children.push(
              <Col
                key={i * 3 + j}
                className="col-12 col-sm-12 col-md-6 col-lg-4"
              >
                <Offer data={this.state.data.destinations[i * 3 + j]} />
              </Col>,
            )
          : children.push(<Col key={i * 3 + j} />);
      }
      //Create the parent and add the children
    }

    table.push(<Row key={1}>{children}</Row>);

    return table;
  };

  render() {
    return (
      <Container
        className="flex-1"
        style={{ marginTop: 100, marginBottom: 100 }}
      >
        {this.state.fetchState === "done" ? (
          this.createTable()
        ) : this.props.from ? (
          <div
            className="text-center"
            style={{ marginTop: 100, marginBottom: 100 }}
          >
            <Loader type="Plane" color="black" height={40} width={40} />
          </div>
        ) : (
          <h1 style={{ textAlign: "center" }}>No origin selected</h1>
        )}
      </Container>
    );
  }
}
