import React from "react";
import {CardText, Col, Row} from "reactstrap";
import {dayDiff, timeConverter} from "../../services/kiwiService";
import {mdiAirplaneTakeoff} from "@mdi/js";
import Icon from "@mdi/react";

export class OfferInfoBar extends React.Component {
  render() {
    let details = (
      <div>
        {this.props.showTimeRange ? (
          <Row>
            <Col>
              <CardText className="text-left font-weight-bold h5">
                {timeConverter(this.props.data.dTime, true, true, true)} -{" "}
                {timeConverter(
                  this.props.data.route[this.props.data.route.length - 1].aTime,
                  false,
                  true,
                  true,
                )}
              </CardText>
            </Col>
          </Row>
        ) : (
          <div />
        )}

        <Row>
          <Col>
            <CardText
              className="text-left text-secondary"
              style={{ fontWeight: 500 }}
            >
              {dayDiff(
                this.props.data.dTime,
                this.props.data.route[this.props.data.route.length - 1].aTime,
              )}{" "}
              nights
            </CardText>
          </Col>
        </Row>

        {this.props.showFromLocation ? (
          <Row className="colPadding">
            <Icon className="text-left" path={mdiAirplaneTakeoff} size={1.0} />
            <CardText
              className="text-left text-secondary ml-2"
              style={{ fontWeight: 500 }}
            >
              {this.props.data.cityFrom}
            </CardText>
          </Row>
        ) : (
          <div />
        )}
      </div>
    );

    return (
      <Row className="offerBarRow">
        <Col className="col-8">{details}</Col>

        <Col className="col-4 m-auto">
          <CardText className="text-right font-weight-bold h1">
            {this.props.data.price}€
          </CardText>
        </Col>
      </Row>
    );
  }
}

export default OfferInfoBar;
