const Images = {
  usa: require("./categories/usa.jpg"),
  adventure: require("./categories/adventure.jpg"),
  party: require("./categories/party.jpg"),
  asia: require("./categories/asia.jpg"),
  megacities: require("./categories/megacities.jpg"),
  paradise: require("./categories/paradise.jpg"),
  capitals: require("./categories/capitals.jpg"),
  wildlife: require("./categories/wildlife.jpg"),
  history: require("./categories/history.jpg"),
  beach: require("./categories/beach.jpg"),
};

export const isoCountriesToPng = {
  AF: require("./flags/afghanistan.png"),
  AX: require("./flags/aland-islands.png"),
  AL: require("./flags/albania.png"),
  DZ: require("./flags/algeria.png"),
  AS: require("./flags/american-samoa.png"),
  AD: require("./flags/andorra.png"),
  AO: require("./flags/angola.png"),
  AI: require("./flags/anguilla.png"),
  // 'AQ' : 'Antarctica',
  AG: require("./flags/antigua-and-barbuda.png"),
  AR: require("./flags/argentina.png"),
  AM: require("./flags/armenia.png"),
  AW: require("./flags/aruba.png"),
  AU: require("./flags/australia.png"),
  AT: require("./flags/austria.png"),
  AZ: require("./flags/azerbaijan.png"),
  BS: require("./flags/bahamas.png"),
  BH: require("./flags/bahrain.png"),
  BD: require("./flags/bangladesh.png"),
  BB: require("./flags/barbados.png"),
  BY: require("./flags/belarus.png"),
  BE: require("./flags/belgium.png"),
  BZ: require("./flags/belize.png"),
  BJ: require("./flags/benin.png"),
  BM: require("./flags/bermuda.png"),
  BT: require("./flags/bhutan.png"),
  BO: require("./flags/bolivia.png"),
  BA: require("./flags/bosnia-and-herzegovina.png"),
  BW: require("./flags/botswana.png"),
  // 'BV' : 'Bouvet Island',
  BR: require("./flags/brazil.png"),
  IO: require("./flags/british-indian-ocean-territory.png"),
  BN: require("./flags/brunei.png"),
  BG: require("./flags/bulgaria.png"),
  BF: require("./flags/burkina-faso.png"),
  BI: require("./flags/burundi.png"),
  KH: require("./flags/cambodia.png"),
  CM: require("./flags/cameroon.png"),
  CA: require("./flags/canada.png"),
  CV: require("./flags/cape-verde.png"),
  KY: require("./flags/cayman-islands.png"),
  CF: require("./flags/central-african-republic.png"),
  TD: require("./flags/chad.png"),
  CL: require("./flags/chile.png"),
  CN: require("./flags/china.png"),
  CX: require("./flags/christmas-island.png"),
  CC: require("./flags/cocos-island.png"),
  CO: require("./flags/colombia.png"),
  KM: require("./flags/comoros.png"),
  CG: require("./flags/republic-of-the-congo.png"),
  CD: require("./flags/democratic-republic-of-congo.png"),
  CK: require("./flags/cook-islands.png"),
  CR: require("./flags/costa-rica.png"),
  CI: require("./flags/ivory-coast.png"),
  HR: require("./flags/croatia.png"),
  CU: require("./flags/cuba.png"),
  CY: require("./flags/cyprus.png"),
  CZ: require("./flags/czech-republic.png"),
  DK: require("./flags/denmark.png"),
  DJ: require("./flags/djibouti.png"),
  DM: require("./flags/dominica.png"),
  DO: require("./flags/dominican-republic.png"),
  EC: require("./flags/ecuador.png"),
  EG: require("./flags/egypt.png"),
  SV: require("./flags/salvador.png"),
  GQ: require("./flags/equatorial-guinea.png"),
  ER: require("./flags/eritrea.png"),
  EE: require("./flags/estonia.png"),
  ET: require("./flags/ethiopia.png"),
  FK: require("./flags/falkland-islands.png"),
  FO: require("./flags/faroe-islands.png"),
  FJ: require("./flags/fiji.png"),
  FI: require("./flags/finland.png"),
  FR: require("./flags/france.png"),
  // 'GF' : 'French Guiana',
  PF: require("./flags/french-polynesia.png"),
  // 'TF' : 'French Southern Territories',
  GA: require("./flags/gabon.png"),
  GM: require("./flags/gambia.png"),
  GE: require("./flags/georgia.png"),
  DE: require("./flags/germany.png"),
  GH: require("./flags/ghana.png"),
  GI: require("./flags/gibraltar.png"),
  GR: require("./flags/greece.png"),
  GL: require("./flags/greenland.png"),
  GD: require("./flags/grenada.png"),
  // 'GP' : 'Guadeloupe',
  GU: require("./flags/guam.png"),
  GT: require("./flags/guatemala.png"),
  GG: require("./flags/guernsey.png"),
  GN: require("./flags/guinea.png"),
  GW: require("./flags/guinea-bissau.png"),
  GY: require("./flags/guyana.png"),
  HT: require("./flags/haiti.png"),
  // 'HM' : 'Heard Island & Mcdonald Islands',
  VA: require("./flags/vatican-city.png"),
  HN: require("./flags/honduras.png"),
  HK: require("./flags/hong-kong.png"),
  HU: require("./flags/hungary.png"),
  IS: require("./flags/iceland.png"),
  IN: require("./flags/india.png"),
  ID: require("./flags/indonesia.png"),
  IR: require("./flags/iran.png"),
  IQ: require("./flags/iraq.png"),
  IE: require("./flags/ireland.png"),
  IM: require("./flags/isle-of-man.png"),
  IL: require("./flags/israel.png"),
  IT: require("./flags/italy.png"),
  JM: require("./flags/jamaica.png"),
  JP: require("./flags/japan.png"),
  JE: require("./flags/jersey.png"),
  JO: require("./flags/jordan.png"),
  KZ: require("./flags/kazakhstan.png"),
  KE: require("./flags/kenya.png"),
  // 'KI' : 'Kiribati',
  KR: require("./flags/south-korea.png"),
  KW: require("./flags/kuwait.png"),
  KG: require("./flags/kyrgyzstan.png"),
  LA: require("./flags/laos.png"),
  LV: require("./flags/latvia.png"),
  LB: require("./flags/lebanon.png"),
  LS: require("./flags/lesotho.png"),
  LR: require("./flags/liberia.png"),
  LY: require("./flags/libya.png"),
  LI: require("./flags/liechtenstein.png"),
  LT: require("./flags/lithuania.png"),
  LU: require("./flags/luxembourg.png"),
  MO: require("./flags/macao.png"),
  MK: require("./flags/republic-of-macedonia.png"),
  MG: require("./flags/madagascar.png"),
  MW: require("./flags/malawi.png"),
  MY: require("./flags/malaysia.png"),
  MV: require("./flags/maldives.png"),
  ML: require("./flags/mali.png"),
  MT: require("./flags/malta.png"),
  MH: require("./flags/marshall-island.png"),
  MQ: require("./flags/martinique.png"),
  MR: require("./flags/mauritania.png"),
  MU: require("./flags/mauritius.png"),
  // 'YT' : 'Mayotte',
  MX: require("./flags/mexico.png"),
  FM: require("./flags/micronesia.png"),
  MD: require("./flags/moldova.png"),
  MC: require("./flags/monaco.png"),
  MN: require("./flags/mongolia.png"),
  ME: require("./flags/montenegro.png"),
  MS: require("./flags/montserrat.png"),
  MA: require("./flags/morocco.png"),
  MZ: require("./flags/mozambique.png"),
  MM: require("./flags/myanmar.png"),
  NA: require("./flags/namibia.png"),
  NR: require("./flags/nauru.png"),
  NP: require("./flags/nepal.png"),
  NL: require("./flags/netherlands.png"),
  // 'AN' : 'Netherlands Antilles',
  // 'NC' : 'New Caledonia',
  NZ: require("./flags/new-zealand.png"),
  NI: require("./flags/nicaragua.png"),
  NE: require("./flags/niger.png"),
  NG: require("./flags/nigeria.png"),
  NU: require("./flags/niue.png"),
  NF: require("./flags/norfolk-island.png"),
  MP: require("./flags/northern-marianas-islands.png"),
  NO: require("./flags/norway.png"),
  OM: require("./flags/oman.png"),
  PK: require("./flags/pakistan.png"),
  PW: require("./flags/palau.png"),
  PS: require("./flags/palestine.png"),
  PA: require("./flags/panama.png"),
  PG: require("./flags/papua-new-guinea.png"),
  PY: require("./flags/paraguay.png"),
  PE: require("./flags/peru.png"),
  PH: require("./flags/philippines.png"),
  PN: require("./flags/pitcairn-islands.png"),
  PL: require("./flags/republic-of-poland.png"),
  PT: require("./flags/portugal.png"),
  PR: require("./flags/puerto-rico.png"),
  QA: require("./flags/qatar.png"),
  // 'RE' : 'Reunion',
  RO: require("./flags/romania.png"),
  RU: require("./flags/russia.png"),
  RW: require("./flags/rwanda.png"),
  // 'BL' : 'Saint Barthelemy',
  // 'SH' : 'Saint Helena',
  KN: require("./flags/saint-kitts-and-nevis.png"),
  LC: require("./flags/st-lucia.png"),
  // 'MF' : 'Saint Martin',
  // 'PM' : 'Saint Pierre And Miquelon',
  VC: require("./flags/st-vincent-and-the-grenadines.png"),
  WS: require("./flags/samoa.png"),
  SM: require("./flags/san-marino.png"),
  ST: require("./flags/sao-tome-and-principe.png"),
  SA: require("./flags/saudi-arabia.png"),
  SN: require("./flags/senegal.png"),
  RS: require("./flags/serbia.png"),
  SC: require("./flags/seychelles.png"),
  SL: require("./flags/sierra-leone.png"),
  SG: require("./flags/singapore.png"),
  SK: require("./flags/slovakia.png"),
  SI: require("./flags/slovenia.png"),
  SB: require("./flags/solomon-islands.png"),
  SO: require("./flags/somalia.png"),
  ZA: require("./flags/south-africa.png"),
  // 'GS' : 'South Georgia And Sandwich Isl.',
  ES: require("./flags/spain.png"),
  LK: require("./flags/sri-lanka.png"),
  SD: require("./flags/sudan.png"),
  SR: require("./flags/suriname.png"),
  // 'SJ' : 'Svalbard And Jan Mayen',
  SZ: require("./flags/swaziland.png"),
  SE: require("./flags/sweden.png"),
  CH: require("./flags/switzerland.png"),
  SY: require("./flags/syria.png"),
  TW: require("./flags/taiwan.png"),
  TJ: require("./flags/tajikistan.png"),
  TZ: require("./flags/tanzania.png"),
  TH: require("./flags/thailand.png"),
  TL: require("./flags/east-timor.png"),
  TG: require("./flags/togo.png"),
  TK: require("./flags/tokelau.png"),
  TO: require("./flags/tonga.png"),
  TT: require("./flags/trinidad-and-tobago.png"),
  TN: require("./flags/tunisia.png"),
  TR: require("./flags/turkey.png"),
  TM: require("./flags/turkmenistan.png"),
  TC: require("./flags/turks-and-caicos.png"),
  TV: require("./flags/tuvalu.png"),
  UG: require("./flags/uganda.png"),
  UA: require("./flags/ukraine.png"),
  AE: require("./flags/united-arab-emirates.png"),
  GB: require("./flags/united-kingdom.png"),
  US: require("./flags/united-states-of-america.png"),
  // 'UM' : 'United States Outlying Islands',
  UY: require("./flags/uruguay.png"),
  UZ: require("./flags/uzbekistn.png"),
  VU: require("./flags/vanuatu.png"),
  VE: require("./flags/venezuela.png"),
  VN: require("./flags/vietnam.png"),
  VG: require("./flags/british-virgin-islands.png"),
  VI: require("./flags/virgin-islands.png"),
  // 'WF' : 'Wallis And Futuna',
  EH: require("./flags/western-sahara.png"),
  YE: require("./flags/yemen.png"),
  ZM: require("./flags/zambia.png"),
  ZW: require("./flags/zimbabwe.png"),
};

export default Images;
