import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap";
import i18next from "i18next";

const supported_languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "es",
    name: "Español",
  },
  {
    code: "sk",
    name: "Slovenčina",
  },
];

const LanguageSelection = (props) => {
  const { color } = props;
  console.log(`LANGUAGE: ${i18next.language.split("-")[0]}`);

  const defaultLanguage = supported_languages.find(
    (lng) => i18next.language.split("-")[0] === lng.code,
  );

  const [language, setLanguage] = useState(
    defaultLanguage ? defaultLanguage : supported_languages[0],
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const toggleLanguage = (language) => {
    i18next.changeLanguage(language.code, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
    });
    setLanguage(language);
  };

  const renderDropdownItem = (language) => (
    <DropdownItem onClick={() => toggleLanguage(language)}>
      {language.name}
    </DropdownItem>
  );

  return (
    <Dropdown
      style={{ marginLeft: "20px", marginRight: "20px" }}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        size="lg"
        style={{
          color: color,
          paddingRight: "20px",
          paddingLeft: "20px",
          backgroundColor: "transparent",
          borderRadius: "15px",
        }}
        caret
      >
        {language.name}
      </DropdownToggle>
      <DropdownMenu style={{ borderRadius: "10px" }}>
        {supported_languages.map((lng) => renderDropdownItem(lng))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelection;
