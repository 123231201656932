import React from "react";
import {IntroContainer} from "../components/intro/IntroContainer";
import Navbar from "../components/main_navbar/MainNavbar";
import Footer from "../components/footer/Footer";

export class HomePage extends React.Component {
  render() {
    return (
      <div className="h-100 d-flex flex-column">
        <Navbar navbarVisibleScroll={1} />

        <IntroContainer />

        <Footer />
      </div>
    );
  }
}
