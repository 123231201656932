/*eslint-disable*/
import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm={3} className="text-center">
            <Translation>
              {(t) => (
                <Link
                  to="/about"
                  className="text-muted m-auto text-decoration-none"
                >
                  {t("ABOUT_US")}
                </Link>
              )}
            </Translation>
          </Col>

          <Col sm={3} className="text-center">
            <Translation>
              {(t) => (
                <Link
                  to="/terms-of-use"
                  className="text-muted m-auto text-decoration-none"
                >
                  {t("TERMS_OF_USE")}
                </Link>
              )}
            </Translation>
          </Col>

          <Col sm={3} className="text-center">
            <Translation>
              {(t) => (
                <Link
                  to="/privacy-policy"
                  className="text-muted m-auto text-decoration-none"
                >
                  {t("PRIVACY_POLICY")}
                </Link>
              )}
            </Translation>
          </Col>

          <Col sm={3} className="text-center">
            <span className="text-muted">© {new Date().getFullYear()}</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
