const getDestinationsUrl =
  "https://api.columbus.world/flights?categories=popular&fly_from=";
const getLocationsUrl =
  "https://api.skypicker.com/locations?active_only=true&location_types=airport&location_types=country&term=";
const getLocationDetails = "https://api.skypicker.com/locations?type=id&id=";

export const loadLocationDetail = (id) => {
  return fetch(getLocationDetails + id, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error("Network response was not ok.");
  });
};

export const loadDestinations = (from) => {
  return fetch(getDestinationsUrl + from, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error("Network response was not ok.");
  });
};

export const loadLocations = (value) => {
  return fetch(getLocationsUrl + value, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error("Network response was not ok.");
  });
};

export const convertApiLocationsToOptions = (data) => {
  let result = [];

  data.locations.forEach((x) =>
    result.push(
      x.type === "airport" || x.type === "city"
        ? {
            mobileLabel: x.city.name,
            label: x.city.name + ", " + x.city.country.name,
            value: x.city.id,
          }
        : { mobileLabel: x.name, label: x.name, key: x.id },
    ),
  );
  result = result.filter(
    (v, i) => result.map((x) => x.label).indexOf(v.label) === i,
  );

  return result;
};

export const convertLocationData = (data) => {
  let location = data.locations[0];

  if (location.type === "airport" || location.type === "city")
    return {
      mobileLabel: location.name,
      label: location.name + ", " + location.country.name,
      value: location.id,
    };
  else
    return {
      mobileLabel: location.name,
      label: location.name,
      value: location.id,
    };
};

export const locationsListToQuery = (locationsList) => {
  let locations = "";
  if (locationsList) {
    locationsList.forEach((x) => (locations += x.value + ","));
    locations = locations.substring(0, locations.length - 1);
  }
  return locations;
};

export const locationIdToSmallImgUrl = (locationId) => {
  return locationId
    ? "http://images.columbus.world/small/" + locationId + ".jpeg"
    : "";
};

export const locationIdToImgUrl = (locationId) => {
  return locationId
    ? "http://images.columbus.world/medium/" + locationId + ".jpeg"
    : "";
};

export const dayDiff = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60;
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

export const timeConverter = (
  UNIX_timestamp,
  skipYear,
  shortMonths,
  skipDay,
) => {
  const a = new Date(UNIX_timestamp * 1000);
  const months = shortMonths
    ? [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
    : [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  // const hour = a.getHours();
  // const min = a.getMinutes();
  // const sec = a.getSeconds();
  return (
    (skipDay ? "" : days[a.getDay()] + " ") +
    date +
    " " +
    month +
    " " +
    (skipYear ? "" : year) /*+ ' ' + hour + ':' + min + ':' + sec*/
  );
};

export const categoriesToString = (categories) => {
  let result = "";
  categories.forEach((x) => (result = result + "#" + x + " "));
  return result;
};
